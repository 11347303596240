import { iconTypes } from 'ui-component-library/base/Icon/Icon';
import * as HighchartsJs from 'highcharts';
import { HeadingLevels } from 'ui-component-library/base/types';

declare global {
	interface Window {
		__model: any;
		__culture: string;
		__ScrollTracker: any;
		epi: any;
		dataLayer: any;
		$kundo_chat: any;
		CookieConsent: any;
		CookieInformation: any;
	}
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace NodeJS {
		interface Global {
			window: any;
			document: any;
			Styled: any;
			React: any;
			ReactDOM: any;
			ReactDOMServer: any;
		}
	}
}

export interface EpiEvent {
	beta?: {
		isEditable: boolean;
		inEditMode: boolean;
		ready: boolean;
	};
}

export type ContentTypes =
	| StandardPageModel
	| StartPageModel
	| ProcessPageModel
	| NavigationPageModel
	| InspirationPageModel
	| ArticlePageModel
	| SearchPageModel
	| SearchPageAdvanceModel
	| ProductGroupPageModel
	| ProductSubGroupPageModel
	| CriteriaServicePageModel
	| CriteriaServiceRequirementPageModel
	| CriteriaServiceRequirementPageV2Model
	| CriteriaServiceComparisonPageModel
	| RiskAnalysisServicePageModel
	| RiskAnalysisPageModel
	| AttachmentPageModel
	| SectionPageModel
	| CalendarEventPageModel
	| PublicationsListingPageModel
	| QuestionPortalStartPageModel
	| QuestionPortalCategoryPageModel
	| QuestionPortalDialogPageModel
	| NewsListingPageModel
	| PublicationPageModel
	| StatisticServiceStartPageModel
	| StatisticServicePageModel
	| StatisticServiceArticlePageModel
	| StatisticServiceNavigationPageModel
	| StatisticServiceSectionPageModel
	| WebGuidanceLandingPageModel
	| WebGuidanceArticlePageModel
	| ValfrihetStartPageModel
	| ValfrihetAdPageModel
	| ValfrihetMonitorPageModel
	| ValfrihetArticlePageModel;

export interface Address {
	name: string;
	street: string;
	zipCode: string;
	city: string;
}

export interface ContactChannel {
	name: string;
	value: string;
	type: string;
}

export interface NavigationItemModel {
	title: string;
	url: string;
	isOpen?: boolean;
	isActive?: boolean;
	items?: NavigationItemModel[];
}

export interface LogotypeModel {
	url?: string;
	alt?: string;
	logo?: string;
}
export interface Model {
	head?: ModelHead;
	languageTags?: Array<{
		culture: string;
		url: string;
	}>;
	metadata?: ModelMetadata;
	content?: ContentTypes;
	logotype?: LogotypeModel;
	navigation?: {
		navigationButtonLabel: string;
		accessibilityDescription: string;
		openNavigationPaneLabel: string;
		closeNavigationPaneLabel: string;
		openNavigationItemLabel: string;
		closeNavigationItemLabel: string;
		items: NavigationItemModel[];
	};
	criteriaCart?: {
		cartLabel: string;
		openCartText: string;
		closeCartText: string;
		apiUrl: string;
		toastCriteriaAdded: string;
	};
	search?: ModelSearch;
	breadcrumbs?: {
		breadcrumbs: ModelBreadcrumbs[];
	};
	quickLinks?: {
		quickLinks: QuickLink[];
	};
	alertBar?: {
		alerts: Array<{
			id: string;
			text: {
				modelType: 'Html';
				fragments: RawFragmentModel[];
			};
			closeButtonLabel: string;
			isCloseable: boolean;
		}>;
	};
	betaBar?: {
		text: {
			modelType: 'Html';
			fragments: RawFragmentModel[];
		};
	};
	quickLinksBar?: {
		label: string;
		links: Array<{
			url: string;
			text: string;
			target?: string;
			icon?: iconTypes;
			modelType?: string;
		}>;
	};
	cookieBar?: {
		heading: string;
		text: {
			modelType: 'Html';
			fragments: RawFragmentModel[];
		};
		buttonText: string;
	};
	skipToMainContent?: {
		text: string;
	};
	contact?: {
		addresses: {
			first?: Address;
			second?: Address;
		};
		channels: {
			heading: string;
			items: ContactChannel[];
		};
		social: ContactChannel[];
	};
	popularPages?: PopularPagesModel;
	quickLinksFooter?: {
		quickLinks: QuickLink[];
	};
	copyrightFooter?: string;
	theme?: {
		color?: ThemeTypes;
	};
	languages?: {
		culture: string;
		languages: Array<{
			id: string;
			text: string;
			name: string;
			url: string;
			isActive: boolean;
		}>;
	};
}

export type ThemeTypes =
	| 'default'
	| 'magenta'
	| 'green'
	| 'orange'
	| 'purple'
	| 'teal'
	| 'none'
	| 'RiskAnalysisService';

export interface ModelSearch {
	apiUrl: string;
	searchPageUrl: string;
	placeholderText: string;
	performSearchText: string;
	showMoreResultsText: string;
	numberOfHitsPerPage: number;
}

export interface QuickLink {
	href: string;
	title: string;
	text: string;
	target: string;
}

export interface ModelBreadcrumbs {
	url: string;
	text: string;
	isActive: boolean;
}

interface ModelMetadata {
	languageTags: string | null;
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	canonicalUrl?: string | null;
	twitter?: {
		card?: string | null;
		title?: string | null;
		description?: string | null;
		image?: string | null;
	};
	openGraph?: {
		title?: string | null;
		siteName?: string | null;
		type?: string | null;
		url?: string | null;
		description?: string | null;
		image?: string | null;
	};
	languages?: LangaugeTag[];
}

interface LangaugeTag {
	culture: string;
	url: string;
}

interface ModelHead {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	openGraphTitle?: string | null;
	openGraphImageUrl?: string | null;
	openGraphDescription?: string | null;
	twitterTitle?: string | null;
	twitterDescription?: string | null;
	twitterImageUrl?: string | null;
}

export interface ModelReact {
	containerId: string;
	clientOnly: boolean;
	apiUrl: string;
	apiEditUrl: string;
	renderingState: RenderingStates;
}

export enum RenderingStates {
	ServerSide = 1,
	Hydrate,
	ClientSide,
}

export interface StartPageModel {
	modelType: 'UpphandlingsmyndighetenStartPage';
	id: string;
	heading: string;
	preamble?: string;
	url?: string;
	navigationList: NavigationListModel;
	introBlock?: IntroBlockModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface StandardPageModel {
	modelType: 'StandardPage';
	id: string;
	heading: string;
	preamble: string;
	text: any;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface ProcessPageModel {
	modelType: 'UpphandlingsmyndighetenProcessPage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	phase?: PhaseModel;
	url?: string;
	text: TextHtmlModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface ArticlePageModel {
	modelType: 'UpphandlingsmyndighetenArticlePage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	relatedLinks?: RelatedLinksModel[];
	url?: string;
	text: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	imageHeader?: ImageHeaderModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface InspirationPageModel {
	modelType: 'UpphandlingsmyndighetenInspirationPage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	url?: string;
	text: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	imageHeader?: ImageHeaderModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface CalendarEventPageModel {
	modelType: 'UpphandlingsmyndighetenCalendarEventPage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	url?: string;
	text: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	imageHeader?: ImageHeaderModel;
	calendarEventDetails?: calendarEventDetailsItemModel[];
	contentArea?: ContentAreaModelTypes[];
}

export interface NavigationPageModel {
	modelType: 'UpphandlingsmyndighetenNavigationPage';
	id: string;
	heading: string;
	preamble?: string;
	url?: string;
	navigationList: NavigationListModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface SearchPageModel {
	modelType: 'UpphandlingsmyndighetenSearchPage';
	id: string;
	heading: string;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface SearchPageAdvanceModel {
	modelType: 'UpphandlingsmyndighetenSearchPageAdvance';
	id: string;
	heading: string;
	url?: string;
	apiUrl: string;
	numberOfHitsPerPage: number;
	showMoreButtonText: string;
	contentArea?: ContentAreaModelTypes[];
	searchResult: SearchResultModel;
	services: ServiceModel[];
	hitsInOtherTab: string;
	zeroHitsHeading: string;
	zeroHitsText: string;
	servicesResultText: string;
	filterHeading: string;
	clearFilterText: string;
	showResultText: string;
	closeFilterModalText: string;
}
export interface CalendarEventListingPageModel {
	modelType: 'UpphandlingsmyndighetenCalendarEventListingPage';
	id: string;
	heading: string;
	preamble?: string;
	showMoreButtonText: string;
	apiUrl: string;
	numberOfHitsPerPage: number;
	listingModel: CalendarEventsListingModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface FilterButtonGroupAggregationsModel {
	name: string;
	type: string;
	path: string;
	buckets: FilterButtonGroupAggregationsBucketModel[];
}

export interface FilterButtonGroupAggregationsBucketModel {
	name: string;
	count?: number;
	term?: string;
	disabled?: boolean;
	selected?: boolean;
}

export interface NewsListingPageModel {
	modelType: 'UpphandlingsmyndighetenNewsListingPage';
	id: string;
	heading: string;
	preamble?: string;
	showMoreButtonText: string;
	apiUrl: string;
	numberOfHitsPerPage: number;
	listingModel: NewsListingModel;
	contentArea?: ContentAreaModelTypes[];
	url?: string;
}

export interface PublicationsListingPageModel {
	modelType: 'UpphandlingsmyndighetenPublicationsListingPage';
	id: string;
	heading: string;
	preamble: string;
	showMoreButtonText: string;
	apiUrl: string;
	url?: string;
	numberOfHitsPerPage: number;
	listingModel: PublicationListingModel;
	contentArea?: ContentAreaModelTypes[];
	downloadText?: string;
}

export interface SectionPageModel {
	modelType: 'UpphandlingsmyndighetenSectionPage';
	id: string;
	heading: string;
	preamble: string;
	apiUrl: string;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface PublicationPageModel {
	modelType: 'UpphandlingsmyndighetenPublicationPage';
	id: string;
	heading: string;
	preamble?: string;
	apiUrl: string;
	articleHeader?: ArticleHeaderModel;
	publicationDetails: publicationDetailsItemModel[];
	url?: string;
	contentArea?: ContentAreaModelTypes[];
	image?: string;
	alt?: string;
	downloadText?: string;
	publicationUrl?: string;
}

export interface CriteriaServiceCartPageModel {
	modelType: 'CriteriaServiceCartPage';
	id: string;
	heading: string;
	noCriteriasHeading: string;
	noCriteriasPreamble?: string;
	preamble?: string;
	tableCaptionText: string;
	downloadHeading: string;
	downloadBlocks: DownloadBlockModel[];
	apiUrl?: string;
	url?: string;
	requirements?: DataTableCartModel;
	emptyCartText: string;
}
export interface DownloadBlockModel {
	heading: string;
	preamble: string;
	generateFileProgressText: string;
	createDownloadUrl: string;
	culture: string;
	buttons: [
		{
			text: string;
			format: string;
		}
	];
}

export interface CriteriaServicePageModel {
	modelType: 'CriteriaServicePage';
	id: string;
	heading: string;
	preamble?: string;
	introBlock?: IntroBlockModel;
	tableOfContents?: TocModel;
	apiUrl?: string;
	filterHeading?: string;
	expandFilterText?: string;
	clearFilterText?: string;
	categorySection?: CategorySectionModel;
	text: TextHtmlModel;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}
export interface ProductGroupPageModel {
	modelType: 'ProductGroup';
	id: string;
	heading: string;
	preamble?: string;
	showMorePreamble?: string;
	showLessPreamble?: string;
	linkList?: LinkListModel[];
	url?: string;
	apiUrl?: string;
	filterHeading?: string;
	expandFilterText?: string;
	clearFilterText?: string;
	requirements?: DataTableModel;
	tableCaptionText?: string;
	downloadHeading: string;
	generateFileButtonText: string;
	generateFileProgressText: string;
	downloadFileButtonText: string;
	text: TextHtmlModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface ProductGroupPageV2Model {
	modelType: 'ProductGroupV2';
	id: string;
	heading: string;
	preamble?: string;
	showMorePreamble?: string;
	showLessPreamble?: string;
	linkList?: LinkListModel[];
	url?: string;
	apiUrl?: string;
	filterHeading?: string;
	expandFilterText?: string;
	clearFilterText?: string;
	requirements?: DataTableProductGroupModel;
	compareButton?: CompareButtonModel;
	tableCaptionText?: string;
	text: TextHtmlModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface ProductSubGroupPageModel {
	modelType: 'ProductSubGroup';
	id: string;
	heading: string;
	preamble?: string;
	showMorePreamble?: string;
	showLessPreamble?: string;
	url?: string;
	apiUrl?: string;
	filterHeading?: string;
	expandFilterText?: string;
	clearFilterText?: string;
	requirements?: DataTableModel;
	tableCaptionText?: string;
	downloadHeading: string;
	generateFileButtonText: string;
	generateFileProgressText: string;
	downloadFileButtonText: string;
	text: TextHtmlModel;
	contentArea?: ContentAreaModelTypes[];
	linkList?: LinkListModel[];
}

export interface ProductSubGroupPageV2Model {
	modelType: 'ProductSubGroupV2';
	id: string;
	heading: string;
	preamble?: string;
	showMorePreamble?: string;
	showLessPreamble?: string;
	linkList?: LinkListModel[];
	url?: string;
	apiUrl?: string;
	filterHeading?: string;
	expandFilterText?: string;
	clearFilterText?: string;
	requirements?: DataTableProductGroupModel;
	compareButton?: CompareButtonModel;
	tableCaptionText?: string;
	text: TextHtmlModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface CriteriaServiceRequirementPageModel {
	modelType: 'CriteriaServiceRequirementPage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	text: TextHtmlModel;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface CriteriaServiceRequirementPageV2Model {
	modelType: 'CriteriaServiceRequirementPageV2';
	id: string;
	levelSwitcherTextWithLinks?: string;
	criteriaInfo?: CriteriaDescriptionListModel;
	tabs?: TabModel[];
	compareButton?: CompareButtonModel;
	addToCartButton: AddToCartButtonModel;
	heading: string;
	preamble?: string;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
	requirementInfo?: any;
}

export interface CriteriaServiceComparisonPageModel {
	modelType: 'CriteriaServiceComparisonPage';
	id: string;
	heading: string;
	preamble?: string;
	url?: string;
	addToDownloadsText: string;
	downloadAddedText: string;
	apiUrl: string;
	columns: ComparisonColumnModel[];
	contentArea?: ContentAreaModelTypes[];
}

export interface RiskAnalysisServicePageModel {
	modelType: 'RiskAnalysisServicePage';
	id: string;
	introBlock?: IntroBlockModel;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	categorySection?: any;
	text: TextHtmlModel;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface RiskAnalysisPageModel {
	modelType: 'RiskAnalysisPage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	text: TextHtmlModel;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface AttachmentPageModel {
	modelType: 'AttachmentPage';
	id: string;
	heading: string;
	tableOfContents?: TocModel;
	url?: string;
	text: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	imageHeader?: ImageHeaderModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface QuestionPortalStartPageModel {
	modelType: 'QuestionPortalStartPage';
	id: string;
	heading: string;
	preamble?: string;
	url?: string;
	navigationList: NavigationListModel;
	introBlock?: IntroBlockModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface QuestionPortalCategoryPageModel {
	modelType: 'QuestionPortalCategoryPage';
	id: string;
	heading?: string;
	preamble?: string;
	showMoreButtonText: string;
	apiUrl: string;
	url?: string;
	numberOfHitsPerPage: number;
	listingModel: DialogCategoryListingModel;
	introBlock: IntroBlockModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface QuestionPortalDialogPageModel {
	modelType: 'QuestionPortalDialogPage';
	id: string;
	heading: string;
	preamble?: string;
	text: string;
	articleHeader?: ArticleHeaderModel;
	user: EditorModel;
	apiUrl: string;
	url?: string;
	listingModel: DialogListingModel;
	commentForm: FormContainerFragmentModel;
	contentArea?: ContentAreaModelTypes[];
	topic: string;
	commentsLocked?: boolean;
	archived?: boolean;
}

export interface StatisticServiceStartPageModel {
	modelType: 'StatisticServiceStartPage';
	id: string;
	heading: string;
	preamble?: string;
	url?: string;
	navigationList: NavigationListModel;
	introBlock?: IntroBlockModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface StatisticServiceArticlePageModel {
	modelType: 'StatisticServiceArticlePage';
	id: string;
	heading: string;
	preamble?: string;
	tableOfContents?: TocModel;
	url?: string;
	text: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	imageHeader?: ImageHeaderModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface StatisticServiceNavigationPageModel {
	modelType: 'StatisticServiceNavigationPage';
	id: string;
	heading: string;
	preamble?: string;
	url?: string;
	text?: TextHtmlModel;
	navigationList: NavigationListModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface StatisticServiceSectionPageModel {
	modelType: 'StatisticServiceSectionPage';
	id: string;
	heading: string;
	preamble: string;
	apiUrl: string;
	url?: string;
	text?: TextHtmlModel;
	articleHeader?: ArticleHeaderModel;
	contentArea?: ContentAreaModelTypes[];
}

export interface StatisticServiceArticleListingPageModel {
	modelType: 'StatisticServiceArticleListingPage';
	id: string;
	heading: string;
	preamble?: string;
	showMoreButtonText: string;
	apiUrl: string;
	numberOfHitsPerPage: number;
	listingModel: NewsListingModel;
	contentArea?: ContentAreaModelTypes[];
}
export interface StatisticServicePageModel {
	modelType: 'UpphandlingsmyndighetenStatisticServicePage';
	id: string;
	heading: string;
	preamble?: string;
	text?: TextHtmlModel;
	url?: string;
	contentArea?: ContentAreaModelTypes[];
}

export interface WebGuidanceLandingPageModel {
	modelType: 'WebGuidanceLandingPage';
	id: string;
	heading: string;
	preamble?: string;
	area: GuidanceAreaModel;
	url?: string;
	articleHeader?: ArticleHeaderModel;
	firstContentArea?: ContentAreaModelTypes[];
	secondContentArea?: ContentAreaModelTypes[];
	firstText?: TextHtmlModel;
	secondText?: TextHtmlModel;
	firstRelatedLinks?: RelatedLinksModel[];
	secondRelatedLinks?: RelatedLinksModel[];
	contentArea?: ContentAreaModelTypes[];
}

export interface WebGuidanceArticlePageModel {
	modelType: 'WebGuidanceArticlePage';
	id: string;
	heading: string;
	preamble?: string;
	area: GuidanceAreaModel;
	text: TextHtmlModel;
	url?: string;
	articleHeader?: ArticleHeaderModel;
	tableOfContents?: TocModel;
	relatedLinks?: RelatedLinksModel[];
	contentArea?: ContentAreaModelTypes[];
}

export interface ValfrihetArticlePageModel {
	modelType: 'ValfrihetArticlePage';
	id: string;
	url?: string;
	mainHeading?: string;
	mainPreamble?: string;
	introBlock: IntroBlockModel;
	subNavigation: [{ url: string; text: string; isActive: boolean }];
	text: TextHtmlModel;
	contentArea: ContentAreaModelTypes[];
}

export interface ValfrihetStartPageModel {
	modelType: 'ValfrihetStartPage';
	filters?: AggregationsModel[];
	id: string;
	url?: string;
	heading?: string;
	preamble?: string;
	introBlock: IntroBlockModel;
	subNavigation: [{ url: string; text: string; isActive: boolean }];
	loginButton?: { text?: string; url?: string };
	searchPlaceholder?: string;
	searchResult?: SearchResultModel;
	showMoreButtonText: string;
	numberOfHitsPerPage: number;
	apiUrl: string;
	contentArea?: ContentAreaModelTypes[];
	searchMonitor?: {
		heading: string;
		preamble: string;
		apiUrl: string;
		buttonText: string;
	};
}

export interface ValfrihetMonitorPageModel {
	modelType: 'ValfrihetMonitorPage';
	id: string;
	url?: string;
	heading: string;
	preamble: string;
	buttonText: string;
	buttonLink: string;
}

export interface ValfrihetAdPageModel {
	modelType: 'ValfrihetAdPage';
	id: string;
	url?: string;
	subNavigation: [{ url: string; text: string; isActive: boolean }];
	heading: string;
	preamble?: string;
	showBackLink?: boolean;
	updated?: string;
	logo?: string;
	button?: { text: string; url: string; color: string; icon: iconTypes };
	uhmNotice?: TextHtmlModel;
	regionType?: string;
	regionName?: string;
	businessAreas?: string[];
	startDate?: string;
	endDate?: string;
	registryNo?: string;
	contactPersons: [
		{
			name?: string;
			email?: string;
			phone?: string;
		}
	];
	contentArea?: ContentAreaModelTypes[];
	registryNumber?: string;
}

export interface CalendarEventsListingModel {
	offset: number;
	fetch: number;
	size: number;
	total: number;
	sortOrder: number;
	hasMultiDayEvents: boolean;
	hasMore: boolean;
	calendarEvents: CalendarEventModel[];
	aggregations: FilterButtonGroupAggregationsModel[];
}

export interface NewsListingModel {
	offset: number;
	fetch: number;
	size: number;
	total: number;
	sortOrder: number;
	items: NewsModel[];
}

export interface DialogCategoryListingModel {
	offset: number;
	fetch: number;
	size: number;
	total: number;
	items: DialogCategoryItemModel[];
}

export interface DialogListingModel {
	comments: DialogBlockModel[];
}

export interface PublicationListingModel {
	offset: number;
	fetch: number;
	size: number;
	total: number;
	sortOrder: number;
	groups: PublicationGroupModel[];
}

export interface CriteriaDescriptionListModel {
	heading: string;
	items: CriteriaDescriptionModel[];
}

export interface CriteriaDescriptionModel {
	heading: string;
	text: string;
}

export interface TabModel {
	tabName: string;
	id: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface CompareButtonModel {
	text: string;
	url: string;
}

export interface AddToCartButtonModel {
	addToDownloadsText: string;
	downloadAddedText: string;
	criteriaDescriptor: string;
}

export interface ComparisonColumnModel {
	selects: ComparisonSelectModel[];
	criteriaInfo?: CriteriaInfoModel;
}

export interface ComparisonSelectModel {
	id?: string;
	path: string;
	name: string;
	options?: ComparisonSelectOptionModel[];
	criteriaInfo?: CriteriaInfoModel;
}

export interface CriteriaInfoModel {
	heading: string;
	url: string;
	criteriaDescriptor: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface ComparisonSelectOptionModel {
	term: string;
	value: string;
	selected: boolean;
}
export interface ListingModel {
	page: number;
	size: number;
	total: number;
	calendarEvents?: CalendarEventModel[];
	news?: NewsModel[];
	groups?: PublicationGroupModel[];
	publications?: PublicationModel[];
}

export interface CategorySectionModel {
	id: string;
	openCategoryLabel: string;
	closeCategoryLabel: string;
	noItemsText: string;
	categories: CategoryModel[];
	aggregations?: AggregationsModel[];
}

export interface CategoryModel {
	heading: string;
	isOpen: boolean;
	items?: Array<{ text: string; url: string; count?: number }>;
}
export interface NewsModel {
	published: string;
	heading: string;
	text: string;
	url: string;
}

export interface DialogCategoryItemModel {
	heading: string;
	url: string;
	numberOfComments: number;
	updated?: string;
}

export interface DialogBlockModel {
	isOrganizationReply: boolean;
	isBestReply?: boolean;
	text: string;
	publishedDate: string;
	updatedDate?: string;
	user: EditorModel;
}

export interface EditorModel {
	name: string;
	title: string;
	image: string;
}
export interface PublicationGroupModel {
	heading: string;
	items: PublicationModel[];
}

export interface PublicationModel {
	id?: string;
	publicationContentId?: string;
	culture?: string;
	url?: string;
	image?: string;
	alt?: string;
	published?: string;
	name?: string;
	description?: string;
	publicationUrl?: string;
	categories?: [];
}

export interface CalendarEventModel {
	start: { day: string; monthNameShort: string };
	end: { day: string; monthNameShort: string };
	heading: string;
	category: string;
	text?: string;
	url: string;
}

export interface NavigationListLinkModel {
	heading: string;
	url: string;
	text: string;
}

export interface NavigationListModel {
	items: NavigationListLinkModel[];
	layout?: 'grid' | 'list';
	accessibilityDescription: string;
}

export interface IntroBlockModel {
	heading?: string;
	preamble?: string;
	hasSearch?: boolean;
	placeholderSearchText: string;
	performSearchText: string;
	searchPageUrl?: string;
	apiUrl?: string;
	icon?: iconTypes;
}

export interface RelatedLinksModel {
	heading: string;
	links?: LinkModel[];
}

export interface TextHtmlModel {
	modelType: 'Html';
	fragments: FragmentModelTypes[];
}

export interface LinkListModel {
	heading: string;
	tooltipLabel: string;
	tooltipText: string;
	width: 50 | 100;
	items: Array<{ text: string; url: string; external?: boolean }>;
}

export interface DataTableModel {
	sortBy?: string;
	orderBy?: string;
	filters?: AggregationsModel[];
	aggregations?: AggregationsModel[];
	query?: string;
	offset?: number;
	fetch?: number;
	numberOfHits?: number;
	properties?: DataTablePropertyModel[];
	items?: DataTableRowModel[];
}

export interface DataTableProductGroupModel {
	sortBy?: string;
	orderBy?: string;
	filters?: AggregationsModel[];
	aggregations?: AggregationsModel[];
	query?: string;
	offset?: number;
	fetch?: number;
	numberOfHits?: number;
	properties?: DataTablePropertyModel[];
	items?: DataTableProductGroupRowModel[];
}

export interface DataTableCartModel {
	properties?: DataTablePropertyModel[];
	items?: DataTableCartRowModel[];
}

export interface AggregationsModel {
	name: string;
	type: string;
	path: string;
	selectedBuckets: number;
	buckets: AggregationsBucketModel[];
	hiddenByDefault?: boolean;
}

export interface AggregationsBucketModel {
	name?: string;
	count: number;
	term: string;
	selected?: boolean;
	disabled?: boolean;
}

export interface FilterButtonGroupAggregationsBucketModel {
	name: string;
	count?: number;
	term?: string;
	disabled?: boolean;
	selected?: boolean;
}

export interface DataTableRowModel {
	documentId: string;
	score: number;
	url?: string;
	requirementName?: string;
	requirementLevelLevel?: string;
	requirementLevelType?: string;
	productGroupId?: number;
	productSubGroupId?: number;
	productSubGroupName?: string;
	requirementWithLevel: RequirementModel;
	description?: string;
}

export interface DataTableProductGroupRowModel {
	url?: string;
	requirementName: string;
	requirementLevelLevel?: string;
	requirementLevelType?: string;
	productSubGroupName?: string;
	addToCartButton: AddToCartButtonModel;
	description?: string;
}

export interface DataTableCartRowModel {
	url?: string;
	requirementName: string;
	requirementLevelLevel?: string;
	productSubGroupName?: string;
	removeFromCartButtonText: string;
	criteriaDescriptor: string;
	warningText?: string;
}

export interface DataTablePropertyModel {
	property: string;
	path: string;
	heading: string;
	direction?: string;
	orderByText: string;
}

export interface TocModel {
	heading: string;
	titles: TocItemModel[];
	chapters: Array<string>;
	isSticky?: boolean;
}

export interface TocItemModel {
	text: string;
	targetId: string;
	titles?: TocItemModel[];
}

export interface ArticleHeaderModel {
	published?: string;
	categories?: ArticleHeaderCategoriesModel[];
	social?: boolean;
}

export interface ArticleHeaderCategoriesModel {
	url?: string;
	text: string;
}

export interface ImageHeaderModel {
	src?: string;
	altText?: string;
	text?: string;
}

export interface calendarEventDetailsItemModel {
	heading: string;
	text: string;
	type: string;
	url?: string;
}

export interface publicationDetailsItemModel {
	text: string;
	bold: boolean;
	heading: string;
	type: string;
}

export interface PhaseModel {
	name: string;
	url: string;
	next?: PhaseModel | null;
	previous?: PhaseModel | null;
	i18n: {
		next: string;
		previous: string;
	};
}

export interface ProductModel {
	name: string;
	code: string;
	price: {
		amount: number;
		currency: string;
	};
	image: string;
	url: string;
}

interface stdImage {
	src: string;
	alt: string;
	sizes: {
		w300: string;
		w600: string;
		w800: string;
	};
}

export interface PuffModel {
	modelType: 'Puff';
	heading: string;
	url: string;
	text: string;
}

export interface StepModel {
	heading: string;
	subHeading?: string;
	id?: string;
	isOpen: boolean;
	fragments: FragmentModelTypes[];
	steps?: StepModel[];
}

export interface CriteriaItemBaseModel {
	heading: string;
	level: number;
	subHeading?: string;
	id?: string;
	url?: string;
	linkText?: string;
}

export interface CriteriaItemModel extends CriteriaItemBaseModel {
	fragments: FragmentModelTypes[];
	items?: CriteriaItemModel[];
}

export interface AssessmentModel {
	riskAreaName?: string;
	targetId?: string;
	step: number;
	stepName: string;
	assessment: string;
	assessmentValue: string;
}

export type GuidanceAreaModel =
	| 'construction'
	| 'default'
	| 'work'
	| 'supplyChains'
	| 'municipalLand';

export type FragmentTypes =
	| 'HeadingFragment'
	| 'RawFragment'
	| 'ListFragment'
	| 'Image'
	| 'StepByStep'
	| 'Video'
	| 'InfoBox'
	| 'TableFragment'
	| 'ReferencesBox'
	| 'ReferencesAccordion'
	| 'RelatedDialogsModel'
	| 'Step'
	| 'FormContainer'
	| 'EmploymentRequirementsCalculationForm'
	| 'Chapter'
	| 'Quote'
	| 'DescriptionList'
	| 'LinkList'
	| 'ServiceButton'
	| 'RequirementLevelComparerModel'
	| 'InfoBoxes'
	| 'EmbeddedScriptModel'
	| 'InfoBoxCriteria'
	| 'RiskAssessmentsMatrix'
	| 'RiskAssessmentsMatrixSmall'
	| 'ClimateImpactEvaluationForm'
	| 'Highcharts'
	| 'StatisticsDataBlock'
	| 'GuidanceInfoBox';

export interface FragmentModel {
	modelType: FragmentTypes;
}

export interface HeadingFragmentModel extends FragmentModel {
	level: number;
	id?: string;
	raw: string;
}

export interface StepFragmentModel extends FragmentModel {
	heading: string;
	id?: string;
	isOpen: boolean;
	fragments: FragmentModelTypes[];
}

export interface RawFragmentModel extends FragmentModel {
	raw: string;
}

export interface EmploymentRequirementsCalculationFormModel
	extends FragmentModel {
	apiUrl: string;
}

export interface ListFragmentModel extends FragmentModel {
	raw: string;
}

export interface ImageFragmentModel extends FragmentModel {
	src: string;
	largeSrc?: string;
	alt: string;
	prop?: string;
	caption?: string;
	zoom?: boolean;
	openBtnLabel?: string;
	closeBtnLabel?: string;
	size?: 'default' | 'medium' | 'large';
	height?: number;
	width?: number;
	loading?: string;
}

export interface VideoFragmentModel extends FragmentModel {
	embedUrl: string;
	missingConsentText: string;
}

export interface InfoBoxFragmentModel extends FragmentModel {
	heading: string;
	tooltipText?: string;
	tooltipLabel?: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
	style: 'warning' | 'default';
	copyToClipboardText?: string;
}

export interface ReferencesBoxFragmentModel extends FragmentModel {
	heading: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface ReferencesAccordionFragmentModel extends FragmentModel {
	heading: string;
	headingLevel: HeadingLevels;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface GuidanceInfoBoxFragmentModel extends FragmentModel {
	image: string;
	alt: string;
	text: string;
	link?: LinkModel;
}

export interface TableFragmentModel extends FragmentModel {
	caption?: string;
	raw: string;
	stickyColumn?: boolean;
}

export interface ChapterFragmentModel extends FragmentModel {
	id: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface QuoteFragmentModel extends FragmentModel {
	text?: string;
	author?: string;
	authorInformation?: string;
}

export interface InfoBoxesModel extends FragmentModel {
	boxes: Array<{
		heading: string;
		text: {
			modelType: 'Html';
			fragments: RawFragmentModel[];
		};
	}>;
}

export interface InfoBoxCriteriaModel extends FragmentModel {
	items: CriteriaItemModel[];
}

export interface RiskAssessmentsMatrixModel extends FragmentModel {
	caption: string;
	rowHeading: string;
	columnHeading: string;
	assessments: AssessmentModel[];
}

export interface RiskAssessmentsMatrixSmallModel extends FragmentModel {
	caption: string;
	heading: string;
	assessments: AssessmentModel[];
}
export interface RequirementLevelComparerModel extends FragmentModel {
	heading: string;
	showDetailsText: string;
	hideDetailsText: string;
	levels: RequirementLevelModel[];
}

export interface EmbeddedScriptModel extends FragmentModel {
	url: string;
}
export interface RequirementLevelModel {
	heading: string;
	url: string;
	isActive: boolean;
	rows: RequirementLevelRowModel[];
}

export interface RequirementLevelRowModel {
	text?: string;
	html?: string;
	modelType: string;
}

export interface DescriptionListFragmentModel extends FragmentModel {
	lineBreak?: boolean;
	sections: DescriptionListModel[];
}

export type chartTypes = 'column' | 'bar' | 'line' | 'pie';
export interface HighchartsBaseModel {
	chartType?: chartTypes;
	stacked?: boolean | 'normal' | 'percent';
	title?: string;
	subtitle?: string;
	tooltipValueDecimals?: number;
	tooltipValuePrefix?: string;
	tooltipValueSuffix?: string;
	yAxisTitle?: string;
	yAxisLabelFormat?: string;
	yAxisMin?: number;
	yAxisMax?: number;
	xAxisTitle?: string;
	xAxisLabelFormat?: string;
	xAxisCategories?: string[];
	showLegend?: boolean;
	series?: HighchartsJs.SeriesOptionsType[];
	chartHeight?: number | string | null;
	exports?: StatisticsDataExportModel;
}

export interface StatisticTableBaseModel {
	title?: string;
	stickyColumn?: boolean;
	matrix?: boolean;
	properties: StatisticTableProperty[];
	rows: { [key: string]: string }[];
	exports?: StatisticsDataExportModel;
}

export interface HighchartsModel extends FragmentModel {
	heading: string;
	source?: string;
	text?: TextHtmlModel;
	chartProperties?: HighchartsBaseModel;
	tableProperties?: StatisticTableBaseModel;
}

export interface StatisticTableModel extends StatisticTableBaseModel {
	offset: number;
	fetch: number;
	total: number;
}

export interface StatisticTableProperty {
	path: string;
	name: string;
	enabled: boolean;
	type: string;
	direction?: string;
	dataType: string;
	description?: string;
}

export interface DescriptionListModel {
	bold: boolean;
	heading: string;
	type: 'text' | 'breadcrumb' | 'listWithLinks';
	text?: string;
	items?: DescriptionListLinkModel[];
}

export interface DescriptionListLinkModel {
	url: string;
	text: string;
	image?: string;
	external?: boolean;
}

export interface LinkListFragmentModel extends FragmentModel {
	items: LinkListLinkModel[];
}

export interface ServiceButtonFragmentModel extends FragmentModel {
	text: string;
	url: string;
	icon?: iconTypes;
	color?: 'magenta' | 'teal';
}
export interface LinkListLinkModel {
	url: string;
	text: string;
	icon?: iconTypes;
	external?: boolean;
}

export interface FormContainerFragmentModel extends FragmentModel {
	id: string;
	currentStepIndex: number;
	method?: string;
	action?: string;
	encodingType?: string;
	title?: string | null;
	description?: string | null;
	redirectUrl?: string;
	steps: any[];
	fields: any;
	confirmationMessage: string | null;
	dependencies?: any[];
}

export interface StepByStepModel extends FragmentModel {
	steps: StepModel[];
	listType: 'none' | 'numbers' | 'letters';
}

export interface RelatedDialogsModel extends FragmentModel {
	heading?: string;
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
	size: number;
	tags: string[];
	dialogs: DialogModel[];
}

export interface DialogModel {
	heading: string;
	text: string;
	url: string;
	numberOfComments: number;
	conversationState: string;
	tags: string[];
	lastActivity: string;
	publishedDate: string;
}

export interface StatisticsDataBlockModel extends FragmentModel {
	heading: string;
	diagram?: StatisticsDataDiagramModel;
	table?: StatisticsDataTableModel;
	id: number | string;
}

export interface StatisticsDataExportModel {
	placeholder: string;
	options: StatisticsDataExportOptionModel[];
}
export interface StatisticsDataExportOptionModel {
	name: string;
	type: string;
	url?: string;
}

export interface StatisticsDataDiagramModel {
	tabName: string;
	isActive: boolean;
	lastUpdated?: string;
	source?: string;
	chartProperties: HighchartsBaseModel;
	exports?: StatisticsDataExportModel;
}

export interface StatisticsDataTableModel {
	tabName: string;
	isActive: boolean;
	lastUpdated?: string;
	source?: string;
	apiUrl: string;
	numberOfHitsPerPage: number;
	showMoreButtonText?: string;
	tableProperties: StatisticTableModel;
	exports?: StatisticsDataExportModel;
}

export type FragmentModelTypes =
	| HeadingFragmentModel
	| RawFragmentModel
	| ListFragmentModel
	| ImageFragmentModel
	| StepByStepModel
	| VideoFragmentModel
	| InfoBoxFragmentModel
	| ReferencesBoxFragmentModel
	| ReferencesAccordionFragmentModel
	| GuidanceInfoBoxFragmentModel
	| TableFragmentModel
	| RelatedDialogsModel
	| StepFragmentModel
	| FormContainerFragmentModel
	| EmploymentRequirementsCalculationFormModel
	| ChapterFragmentModel
	| DescriptionListFragmentModel
	| LinkListFragmentModel
	| ServiceButtonFragmentModel
	| RequirementLevelComparerModel
	| InfoBoxesModel
	| EmbeddedScriptModel
	| InfoBoxCriteriaModel
	| RiskAssessmentsMatrixModel
	| RiskAssessmentsMatrixSmallModel
	| HighchartsModel
	| StatisticsDataBlockModel;

export interface CalendarEventPuffWithImageModel extends BlockModel {
	image?: string;
	alt?: string;
	heading: string;
	url: string;
	text?: string;
	eventDate?: string;
	eventMonth?: string;
	category?: string;
}

export interface PuffWithImageModel extends BlockModel {
	image?: string;
	alt?: string;
	heading: string;
	url: string;
	text?: string;
	published: string;
	categories?: Array<{ url: string; text: string }>;
	isRounded?: boolean;
}

export interface PuffWithChartModel extends BlockModel {
	chartProperties: HighchartsBaseModel;
	heading: string;
	url: string;
	text?: string;
	published?: string;
	categories?: Array<{ url: string; text: string }>;
}
export interface CalendarEventListBlockModel extends BlockModel {
	heading: string;
	link: string;
	linkText: string;
	hasMultiDayEvents: boolean;
	items: CalendarEventModel[];
}

export interface ServicesListBlockModel extends BlockModel {
	heading: string;
	items: ServiceBlockModel[];
}

export interface ServiceBlockModel {
	heading: string;
	text?: string;
	url: string;
	icon: iconTypes;
}
export interface NewsListBlockModel extends BlockModel {
	heading: string;
	link: string;
	linkText?: string;
	items?: NewsModel[];
}

export interface SectionBlockModel extends BlockModel {
	background: boolean;
	items?: ContentAreaModelTypes[];
}

export interface SectionColumnBlockModel extends BlockModel {
	heading?: string;
	id?: string;
	text?: string;
	items?: ContentAreaModelTypes[];
}

export interface TextBlockModel extends BlockModel {
	text: {
		modelType: 'Html';
		fragments: RawFragmentModel[];
	};
}

export interface DialogListBlockModel extends BlockModel {
	heading: string;
	url: string;
	linkText?: string;
	dialogs?: DialogCategoryItemModel[];
}

export interface EditorsBlockModel extends BlockModel {
	showNumberOfEditors: number;
	showMoreButtonText: string;
	showLessButtonText: string;
	editors: EditorModel[];
}

export interface AskQuestionButtonModel {
	modelType: 'AskQuestion';
	text: string;
	closeModalButtonText: string;
	formData: FormContainerFragmentModel;
}

export interface ChatWithUsButtonModel {
	modelType: 'ChatWithUs';
	text: string;
	time: string;
}

export interface CallUsButtonModel {
	modelType: 'CallUs';
	text: string;
	phone: string;
	time: string;
}

export interface TextButtonModel {
	modelType: 'text';
	text: string;
}

export type ButtonTypes =
	| AskQuestionButtonModel
	| ChatWithUsButtonModel
	| CallUsButtonModel
	| TextButtonModel;

export interface ButtonsAndStuffModel extends BlockModel {
	heading?: string;
	text?: string;
	id: string;
	items: ButtonTypes[];
}

export type ContentAreaModelTypes =
	| SectionBlockModel
	| SectionColumnBlockModel
	| PuffModel
	| CalendarEventPuffWithImageModel
	| PuffWithImageModel
	| PuffWithChartModel
	| NewsListBlockModel
	| CalendarEventListBlockModel
	| TextBlockModel
	| DialogListBlockModel
	| EditorsBlockModel
	| ButtonsAndStuffModel
	| ServicesListBlockModel;

export type BlockTypes =
	| 'SectionBlock'
	| 'SectionColumnSmall'
	| 'SectionColumnMedium'
	| 'SectionColumnLarge'
	| 'Puff'
	| 'PuffWithImage'
	| 'PuffWithNoImage'
	| 'PuffWithChart'
	| 'CalendarEventPuffWithImage'
	| 'CalendarEventPuffWithNoImage'
	| 'CalendarEventListBlock'
	| 'NewsListBlock'
	| 'TextBlock'
	| 'Dialogs'
	| 'DialogsSmall'
	| 'DialogsMedium'
	| 'DialogsLarge'
	| 'Editors'
	| 'EditorsSmall'
	| 'EditorsMedium'
	| 'EditorsLarge'
	| 'ButtonsAndStuff'
	| 'ServicesList';

export interface BlockModel {
	modelType: BlockTypes;
}

export type SearchListItemTypes =
	| SearchResultItemModel
	| DialogSearchHitItemModel;

export interface SearchResultModel {
	query: string;
	page: number;
	offset: number;
	fetch: number;
	size: number;
	numberOfHits: number;
	buckets: any[];
	results: SearchResultItemModel[];
	filterGroups: SearchFilterGroupModel[];
	sorting: SearchSortingModel;
	aggregations: AggregationsModel[];
}

export interface SearchSortingModel {
	path: string;
	placeholder: string;
	options: SortingOptionModel[];
}

export interface SortingOptionModel {
	name: string;
	path: string;
	selected: boolean;
}
export interface SearchFilterGroupModel {
	groupName: string;
	selectedBuckets: number;
	aggregations: SearchAggregationModel[];
}
export interface SearchAggregationModel {
	name: string;
	type: string;
	path: string;
	searchPlaceholder?: string;
	aggregatorType: 'radio' | 'multiselect' | 'select';
	buckets: SearchResultAggregationsBucketModel[];
}

export interface SearchResultAggregationsBucketModel {
	term: string;
	selected: boolean;
}

export interface SearchResultItemModel {
	documentId: string;
	score: number;
	url: string;
	heading: string;
	excerpt: string;
	breadcrumbs?: {
		breadcrumbs: ModelBreadcrumbs[];
	};
	categories: string[];
	modelType: string;
}

export interface DialogSearchHitItemModel {
	documentId: string;
	numberOfComments: number;
	url: string;
	heading: string;
	excerpt?: string;
	categories: string[];
	modelType: string;
}

export interface ValfrihetSearchResultModel {
	documentId: string;
	score: number;
	url: string;
	heading: string;
	excerpt: string;
	businessArea: string;
	regionName: string;
	updated: string;
	modelType: string;
}

export interface ServiceModel {
	name: string;
	isActive: boolean;
	url: string;
	apiUrl: string;
	id: string;
	performSearchText: string;
}

export interface PopularPagesModel {
	first: PopularPagesLinksModel;
	second: PopularPagesLinksModel;
}

export interface PopularPagesLinksModel {
	heading: string;
	links: LinkModel[];
}

export interface LinkModel {
	href: string;
	text: string;
	title: string;
	target: string;
	external?: boolean;
}

export interface CriteriaLevelsModel {
	heading: string;
	levels: CriteriaLevelModel[];
}

export interface CriteriaLevelModel {
	title: string;
	url: string;
	active: boolean;
	fragments: FragmentModelTypes[];
}

export interface RequirementModel {
	id: string;
	level: string;
	selected: boolean;
}

export interface SelectedRequirementsModel {
	[id: string]: RequirementModel;
}

export interface ProductGroupSelectedRequirementsModel {
	[productGroupId: number]: {
		selectedRequirements: SelectedRequirementsModel;
	};
}

export interface RequirementExportModel {
	message?: string;
	reportUrl?: string;
}

export interface CriteriaCartModel {
	criterias: string[];
	model?: CriteriaServiceCartPageModel;
	donloadFileUrl?: string;
}
